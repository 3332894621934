<template>
	<div>
		<div style="min-height: 70vh">
			<el-table :data="plan_files">
				<el-table-column prop="b" label="方案名称" width="350">
					<template #default="scope">
						<el-link type="primary" :underline="false" @click="sp_scheme(scope.row)">{{ scope.row.fileName }}</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="upBdst" label="所属工区" width="180"/>
				<el-table-column prop="planNo" label="方案编号" width="180"/>
				<el-table-column prop="planType" label="方案类型" width="180"/>
				<el-table-column prop="planLevel" label="方案级别" width="180"/>
				<el-table-column prop="giveLevel" label="审批层级" width="180"/>
				<el-table-column prop="expertDemo" label="是否专家论证" width="180"/>
				<el-table-column prop="planTime" label="(计划)编制时间" width="150"/>
				<el-table-column prop="a" label="审批状态" width="150">
					<template #default="scope">
						<el-tag v-if="scope.row.lcFlag === 0" type="danger">驳回</el-tag>
						<el-tag v-else-if="scope.row.lcFlag === 7" type="success">审批完成</el-tag>
						<el-tag v-else type="primary">审批中</el-tag>
					</template>
				</el-table-column>
				<!--<el-table-column prop="a" label="操作" width="200">-->
				<!--	<template #default="scope">-->
				<!--		<el-button type="primary" @click="update_room(scope.row)"> 修改 </el-button>-->
				<!--		<el-popconfirm-->
				<!--			width="220"-->
				<!--			confirm-button-text="确认"-->
				<!--			cancel-button-text="取消"-->
				<!--			:icon="InfoFilled"-->
				<!--			icon-color="#626AEF"-->
				<!--			title="是否确认删除?"-->
				<!--			@confirm="del_room(scope.row)"-->
				<!--		>-->
				<!--			<template #reference>-->
				<!--				<el-button type="danger"> 删除 </el-button>-->
				<!--			</template>-->
				<!--		</el-popconfirm>-->

				<!--	</template>-->
				<!--</el-table-column>-->
			</el-table>
		</div>
		<!--	审批弹窗-->
		<div>
			<el-dialog
				v-model="agreeDialogVisible"
				:title="title"
				width="60%"
				:before-close="handleClose"
			>
				<div>
					<div>
						<el-tag>附件:</el-tag>
						<div v-for="(item, index) in files" :key="index">
							<div v-if="item.fileName.split('.')[1].includes('doc')" style="margin-top: 2%">
								<el-button @click="downLoad(item.fileUrl)">
									<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/docx.png"
									     style="width: 30px;padding-top: 2%"/>
									{{ item.fileName.split('.')[0] }}
								</el-button>
							</div>
							<div v-if="item.fileName.split('.')[1].includes('pdf')" style="margin-top: 2%">
								<el-button @click="downLoad(item.fileUrl)">
									<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/PDF.png"
									     style="width: 30px;padding-top: 2%"/>
									{{ item.fileName.split('.')[0] }}
								</el-button>
							</div>
							<div v-if="item.fileName.split('.')[1].includes('xls')" style="margin-top: 2%">
								<el-button @click="downLoad(item.fileUrl)">
									<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/xlsx.png"
									     style="width: 30px;padding-top: 2%"/>
									{{ item.fileName.split('.')[0] }}
								</el-button>
							</div>
						</div>
					</div>
					<el-divider/>
					<!--	流程进度-->
					<div>
						<el-steps direction="vertical" :space="100" :active="0">
							<el-step v-for="(item, index) in lcs" :title="item.operateUser + '----------' + item.operateFlag"
							         :description="item.operateTime + '  ' +item.operateMessage"/>
							<!--<el-step title="正在处理...." />-->
						</el-steps>
					</div>
				</div>
			</el-dialog>
		</div>
		<!--分页-->
		<div style="float: right;margin-top: 20px;">
			<el-pagination v-model:currentPage="pagenum"
			               :small="true"
			               background layout="prev, pager, next" :total="totals">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {Decrypt} from "@/utils/secret";
import {setNowTimes} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER']),
		...mapGetters(['GET_USERID'])
	},
	data() {
		return {
			plan_files: [],
			agreeDialogVisible: false,
			files: [],
			lcs: [],
			title: '',
			operate_message: '',
			file: '',
			pagenum: 1,
			totals: 0
		}
	},
	created() {
		this.get_already()
	},
	methods: {
		get_already() {
			this.axios.get('/edPlanFile/getElaryFileByUserId', (response) => {
				this.plan_files = response.obj.records;
				this.totals = response.obj.pages * 10;
				console.log(this.plan_files)
			}, {
				user_id: this.GET_USERID,
				pageCount: 1
			})
		},
		sp_scheme(item) {
			this.file = item
			this.title = item.fileName
			this.agreeDialogVisible = true
			this.axios.get('/edPlanFile/getLcFileById', (response) => {
				this.lcs = response.obj
				this.files = response.obj2
			}, {
				id: item.id,
				flag: 0
			})
		},
		handleClose() {
			this.agreeDialogVisible = false
			this.get_already()
		},
		downLoad(item) {
			window.open(Decrypt(item))
		}
	},
	watch: {
		pagenum(){
			this.axios.get('/edPlanFile/getElaryFileByUserId', (response) => {
				this.plan_files = response.obj.records;
				this.totals = response.obj.pages * 10;
			}, {
				user_id: this.GET_USERID,
				pageCount: this.pagenum
			})
		}
	}
}
</script>

<style>

</style>