<template>
	<div>
		<!--方案名称搜索-->
		<div>
			<el-input placeholder="请输入要搜索的方案名称" v-model="search_file_name" @change="get_pend()"/>
		</div>
		<div style="min-height: 65vh">
			<el-table :data="plan_files">
				<el-table-column fixed prop="b" label="方案名称" width="350">
					<template #default="scope">
						<el-link type="primary" :underline="false" @click="sp_scheme(scope.row)">{{ scope.row.fileName }}</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="upBdst" label="所属工区" width="150"/>
				<el-table-column prop="planNo" label="方案编号" width="200"/>
				<el-table-column prop="planType" label="方案类型" width="180"/>
				<el-table-column prop="planLevel" label="方案级别" width="150"/>
				<el-table-column prop="giveLevel" label="审批层级" width="120"/>
				<el-table-column prop="expertDemo" label="是否专家论证" width="150"/>
				<el-table-column prop="planTime" label="(计划)编制时间" width="150"/>
				<el-table-column prop="a" label="操作" width="500">
					<template #default="scope">
						<el-tag type="danger" v-if="scope.row.lcFlag !== 7">审批未完成</el-tag>
						<el-tag type="success" v-if="scope.row.lcFlag === 7">审批完成</el-tag>
						<el-button type="danger" size="mini" v-if="scope.row.lcFlag === 7" @click="upLastFile(scope.row)">上传最终方案</el-button>
						<el-button type="warning" size="mini" v-if="scope.row.lcFlag === 7" @click="up_owner(scope.row)">更新业主审批节点</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!--	审批弹窗-->
		<div>
			<el-dialog
				v-model="agreeDialogVisible"
				:title="title"
				width="60%"
				:before-close="handleClose"
			>
				<div>
					<div>
						<el-tag>附件:</el-tag>
						<div v-for="(item, index) in files" :key="index">
							{{ item.fileName.split('.')[1].includes('doc') }}
							<div v-if="item.fileName.split('.')[1].includes('doc')" style="margin-top: 2%">
								<el-button @click="downLoad(item.fileUrl)">
									<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/docx.png"
									     style="width: 30px;padding-top: 2%"/>
									{{ item.fileName.split('.')[0] }}
								</el-button>
							</div>
							<div v-if="item.fileName.split('.')[1].includes('pdf')" style="margin-top: 2%">
								<el-button @click="downLoad(item.fileUrl)">
									<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/PDF.png"
									     style="width: 30px;padding-top: 2%"/>
									{{ item.fileName.split('.')[0] }}
								</el-button>
							</div>
							<div v-if="item.fileName.split('.')[1].includes('xls')" style="margin-top: 2%">
								<el-button @click="downLoad(item.fileUrl)">
									<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/xlsx.png"
									     style="width: 30px;padding-top: 2%"/>
									{{ item.fileName.split('.')[0] }}
								</el-button>
							</div>
						</div>
					</div>
					<el-divider/>
					<!--	流程进度-->
					<div>
						<el-steps direction="vertical" :space="100" :active="0">
							<el-step v-for="(item, index) in lcs" :title="item.operateUser + '----------' + item.operateFlag"
							         :description="item.operateTime + '  ' +item.operateMessage"/>
							<!--<el-step title="正在处理...." />-->
						</el-steps>
					</div>
				</div>
			</el-dialog>
		</div>
		<!--分页-->
		<div style="float: right;margin-top: 20px;">
			<el-pagination v-model:currentPage="pagenum"
			               :small="true"
			               background layout="prev, pager, next" :total="totals">
			</el-pagination>
		</div>
	<!--	上传最终方案弹窗-->
		<div>
			<el-dialog
				v-model="dialogUpFileVisible"
				title="上传最终方案"
				width="60%"
				:before-close="handleCloseUpFile"
			>
				<div>
					<el-upload
						ref="upload"
						class="upload-demo"
						action="#"
						:http-request="upfile"
						:auto-upload="false"
						:data="dataup"
						:on-change="imgchange"
						:on-remove="handleRemove"
						:multiple="false"
						style="width:98%;"
					>
						<el-button type="danger" size="mini" style="width:98%;">上传最终方案</el-button>
					</el-upload>
				</div>
				<template #footer>
                    <span class="dialog-footer">
                        <el-button @click="handleCloseUpFile">取消</el-button>
                        <el-button type="primary" @click="upfile"> 确认 </el-button>
                    </span>
				</template>
			</el-dialog>
		</div>
		<!--	业主审批-->
		<div>
			<el-dialog
				v-model="dialogOwnerVisible"
				title="更新业主审批流程"
				width="60%"
				:before-close="handleCloseOwner"
			>
				<div>
					<el-select v-model="owner" class="m-2" placeholder="请选择更新层级" size="small">
						<el-option
							v-for="item in owners"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</div>
				<template #footer>
                    <span class="dialog-footer">
                        <el-button @click="handleCloseOwner">取消</el-button>
                        <el-button type="primary" @click="upOwnerBut"> 确认 </el-button>
                    </span>
				</template>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {Decrypt} from "@/utils/secret";
import {setNowTimes} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER']),
		...mapGetters(['GET_USERID'])
	},
	data() {
		return {
			plan_files: [],
			agreeDialogVisible: false,
			files: [],
			lcs: [],
			title: '',
			operate_message: '',
			file: '',
			pagenum: 1,
			totals: 0,
			search_file_name: '',
			dataup: {
				file: '',
				userid: ''
			},
			fileList: [],
			dialogUpFileVisible: false,
			up_last_fileid: '',
			owners: [
				{
					value: '监理单位',
					label: '监理单位',
				},
				{
					value: '康定项目部',
					label: '康定项目部',
				},
				{
					value: '四川公司',
					label: '四川公司',
				},
				{
					value: '川藏公司',
					label: '川藏公司',
				}
			],
			owner: '',
			dialogOwnerVisible: false,
			owner_file_id: ''
		}
	},
	created() {
		this.get_pend()
	},
	methods: {
		get_pend() {
			this.axios.get('/edPlanFile/getFileShow', (response) => {
				this.plan_files = response.obj.records;
				this.totals = response.obj.pages * 10;
			}, {
				file_name: this.search_file_name,
				user_name: this.GET_USER,
				pageCount: 1,
				bdst: this.GET_BDST
			})
		},
		sp_scheme(item) {
			this.file = item
			this.title = item.fileName
			this.agreeDialogVisible = true
			this.axios.get('/edPlanFile/getLcFileById', (response) => {
				this.lcs = response.obj
				this.files = response.obj2
			}, {
				id: item.id,
				flag: 0
			})
		},
		handleClose() {
			this.agreeDialogVisible = false
			this.get_pend()
		},
		downLoad(item) {
			window.open(Decrypt(item))
		},
		imgchange(file) {
			this.fileList.push(file);
		},
		handleRemove(file) {
			for (let i = 0; i < this.fileList.length; i++) {
				if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
					this.fileList.splice(i, 1)
				}
			}
		},
		upLastFile(item){
			this.up_last_fileid = item.id
			this.fileList = []
			this.dataup.file = ''
			this.dataup.userid = ''
			this.dialogUpFileVisible = true

		},
		handleCloseUpFile(){
			this.fileList = []
			this.dataup.file = ''
			this.dataup.userid = ''
			this.dialogUpFileVisible = false
		},
		upfile() {
			//https://www.cr12cz.cn/czscsms/up/upload
			this.fileList.forEach((item, index) => {
				this.axios.post("https://www.cr12cz.cn/czscsms/up/upload", (response) => {
					if (response.obj) {
						ElMessage.success('上传成功!')
						this.reload()
						this.axios.post('/edPlanFileUrl/saveFileUrl', (response1) => {
							if (!response1.obj){
								ElMessage.error('提交失败，请联系科信部!')
							}else {
								this.fileList = []
								this.dataup = {}
								this.$refs.uploadDemo.clearFiles()
								this.handleCloseUpFile()
							}
						},{
							file_name: response.message,
							file_url: response.obj,
							main_id: this.up_last_fileid,
							last_flag: 1
						})

					}else {
						ElMessage.error('提交失败，请联系科信部!')
					}
				}, {
					file: item.raw,
					userid: this.GET_USERID
				})
			})
		},
		up_owner(item){
			this.owner_file_id = item.id
			this.dialogOwnerVisible = true
		},
		handleCloseOwner(){
			this.owner = ''
			this.dialogOwnerVisible = false

		},
		upOwnerBut(){
			this.axios.post('/edOwner/saveOwner', (response) => {
				if (response.obj){
					ElMessage.success('更新成功!')
					this.reload()
				}
			},{
				file_id: this.owner_file_id,
				owner_lc: this.owner,
				up_time: setNowTimes(new Date()),
				up_user: this.GET_USER
			})
		}


	},
	watch: {
		pagenum(){
			this.axios.get('/edPlanFile/getFileShow', (response) => {
				this.plan_files = response.obj.records;
				this.totals = response.obj.pages * 10;
			}, {
				file_name: this.search_file_name,
				user_name: this.GET_USER,
				pageCount: this.pagenum,
				bdst: this.GET_BDST
			})
		}
	}
}
</script>

<style>

</style>