<template>
	<div>
		<div>
			<Header></Header>
		</div>
		<div id="admindiv1">
			<div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall">首页</el-breadcrumb-item>
					<el-breadcrumb-item style="font-size: 13px">基础信息管理</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div id="forms_div2">
			<el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
				<el-tab-pane label="方案管理" name="d"><All_scheme_index v-if="d" /></el-tab-pane>
				<el-tab-pane label="方案上传管理" name="a" v-if="GET_BDST === '1' || GET_POSION === '工区部长'"><Save_index v-if="a" /></el-tab-pane>
				<el-tab-pane label="方案审批管理" name="e" v-if="GET_BDST === '1' || (GET_POSION === '工区安全总监' || GET_POSION === '工区总工')"><Approval_index v-if="e" /></el-tab-pane>
				<el-tab-pane label="方案驳回管理" name="b"  v-if="GET_BDST === '1' || GET_POSION === '工区部长'"><Back_index v-if="b" /></el-tab-pane>
				<el-tab-pane label="抄送我的" name="f"  ><My_copy v-if="f" /></el-tab-pane>
				<el-tab-pane label="审批人员管理" name="c" v-if="GET_BDST === '1'" ><Admin_index v-if="c" /></el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import Header from "@/views/header.vue";
import Save_index from "@/views/scheme/save/index.vue";
import Approval_index from "@/views/scheme/approval/approval_index.vue";
import Back_index from "@/views/scheme/back/back_index.vue";
import Admin_index from "@/views/scheme/admin/admin_index.vue";
import All_scheme_index from "@/views/scheme/all_scheme/all_scheme_index.vue";
import My_copy from "@/views/scheme/copy/my_copy.vue";

export default {
	name: "",
	components: {Save_index, Header, Approval_index, Back_index, Admin_index, All_scheme_index, My_copy},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER']),
		...mapGetters(['GET_POSION'])
	},
	data() {
		return {
			activeName: 'd',
			a: false,
			b: false,
			e: false,
			c :false,
			d: true,
			f: false
		}
	},
	created() {
		if (!this.$route.params.key){
			this.$router.push("/manage")
		}
		if (sessionStorage.getItem('scheme_index_tab')){
			this.activeName = sessionStorage.getItem('scheme_index_tab');
			if (this.activeName === 'a'){
				this.a = true
				this.b = false
				this.e = false
				this.c = false
				this.d = false
				this.f = false
			}
			if (this.activeName === 'b'){
				this.b = true
				this.a = false
				this.e = false
				this.c = false
				this.d = false
				this.f = false
			}
			if (this.activeName === 'e'){
				this.b = false
				this.a = false
				this.e = true
				this.c = false
				this.d = false
				this.f = false
			}
			if (this.activeName === 'c'){
				this.b = false
				this.a = false
				this.c = true
				this.e = false
				this.d = false
				this.f = false
			}
			if (this.activeName === 'd'){
				this.b = false
				this.a = false
				this.c = false
				this.e = false
				this.d = true
				this.f = false
			}
			if (this.activeName === 'f'){
				this.b = false
				this.a = false
				this.c = false
				this.e = false
				this.d = false
				this.f = true
			}
		}
	},
	methods: {
		handleClick(tab, event){
			sessionStorage.setItem('scheme_index_tab', tab.props.name)
			if (tab.props.name === 'a'){
				this.a = true
				this.b = false
				this.e = false
				this.c = false
				this.d = false
				this.f = false
			}
			if (tab.props.name === 'b'){
				this.b = true
				this.a = false
				this.e = false
				this.c = false
				this.d = false
				this.f = false
			}
			if (tab.props.name === 'e'){
				this.b = false
				this.a = false
				this.e = true
				this.c = false
				this.d = false
				this.f = false
			}
			if (tab.props.name === 'c'){
				this.b = false
				this.a = false
				this.c = true
				this.e = false
				this.d = false
				this.f = false
			}
			if (tab.props.name === 'd'){
				this.b = false
				this.a = false
				this.c = false
				this.e = false
				this.d = true
				this.f = false
			}
			if (tab.props.name === 'f'){
				this.b = false
				this.a = false
				this.c = false
				this.e = false
				this.d = false
				this.f = true
			}
		},
		reall(){
			sessionStorage.removeItem('scheme_index_tab');
		}
	}
}
</script>

<style>
#admindiv1{
	border-radius: 5px;
	background-color: white;
	display: flex;
	width: 95%;
	height: 40px;
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	justify-content: space-between;
}
#forms_div2{
	border-radius: 5px;
	margin: 0 auto;
	background-color: white;
	width: 95%;
	padding-top: 20px;
	padding-bottom: 20px;
	min-height: 500px;
}
</style>