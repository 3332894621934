<template>
	<div>
		<!--方案名称搜索-->
		<div>
			<el-input placeholder="请输入要搜索的方案名称" v-model="search_file_name" @change="get_pend()"/>
		</div>
		<div style="min-height: 65vh">
			<el-table :data="plan_files">
				<el-table-column fixed prop="fileName" label="方案名称" width="350" />
					<!--<template #default="scope">-->
					<!--	<el-link type="primary" :underline="false" @click="sp_scheme(scope.row)">{{ scope.row.fileName }}</el-link>-->
					<!--</template>-->
				<!--</el-table-column>-->
				<el-table-column prop="upBdst" label="所属工区" width="180"/>
				<el-table-column prop="planNo" label="方案编号" width="180"/>
				<el-table-column prop="planType" label="方案类型" width="180"/>
				<el-table-column prop="planLevel" label="方案级别" width="180"/>
				<el-table-column prop="giveLevel" label="审批层级" width="150"/>
				<el-table-column prop="expertDemo" label="是否专家论证" width="150"/>
				<el-table-column prop="planTime" label="(计划)编制时间" width="150"/>
				<el-table-column prop="ownerLc" label="业主审批节点" width="150"/>
				<el-table-column prop="upUser" label="业主审批时间" width="150"/>
				<el-table-column prop="a" label="操作" width="120">
					<template #default="scope">
						<el-tag type="danger" v-if="scope.row.lcFlag !== 7">审批未完成</el-tag>
						<el-tag type="success" v-if="scope.row.lcFlag === 7">审批完成</el-tag>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!--	审批弹窗-->
		<div>
			<el-dialog
				v-model="agreeDialogVisible"
				:title="title"
				width="60%"
				:before-close="handleClose"
			>
				<div>
					<div>
						<el-tag>附件:</el-tag>
						<div v-for="(item, index) in files" :key="index">
							<div v-if="item.fileName.split('.')[1].includes('doc')" style="margin-top: 2%">
								<el-button @click="downLoad(item.fileUrl)">
									<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/docx.png"
									     style="width: 30px;padding-top: 2%"/>
									{{ item.fileName.split('.')[0] }}
								</el-button>
							</div>
							<div v-if="item.fileName.split('.')[1].includes('pdf')" style="margin-top: 2%">
								<el-button @click="downLoad(item.fileUrl)">
									<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/PDF.png"
									     style="width: 30px;padding-top: 2%"/>
									{{ item.fileName.split('.')[0] }}
								</el-button>
							</div>
							<div v-if="item.fileName.split('.')[1].includes('xls')" style="margin-top: 2%">
								<el-button @click="downLoad(item.fileUrl)">
									<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/xlsx.png"
									     style="width: 30px;padding-top: 2%"/>
									{{ item.fileName.split('.')[0] }}
								</el-button>
							</div>
						</div>
					</div>
					<el-divider/>
					<!--	流程进度-->
					<div>
						<el-steps direction="vertical" :space="100" :active="0">
							<el-step v-for="(item, index) in lcs" :title="item.operateUser + '----------' + item.operateFlag"
							         :description="item.operateTime + '  ' +item.operateMessage"/>
							<!--<el-step title="正在处理...." />-->
						</el-steps>
					</div>
				</div>
			</el-dialog>
		</div>
		<!--分页-->
		<div style="float: right;margin-top: 20px;">
			<el-pagination v-model:currentPage="pagenum"
			               :small="true"
			               background layout="prev, pager, next" :total="totals">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {Decrypt} from "@/utils/secret";
import {setNowTimes} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER']),
		...mapGetters(['GET_USERID'])
	},
	data() {
		return {
			plan_files: [],
			agreeDialogVisible: false,
			files: [],
			lcs: [],
			title: '',
			operate_message: '',
			file: '',
			pagenum: 1,
			totals: 0,
			search_file_name: ''
		}
	},
	created() {
		this.get_pend()
	},
	methods: {
		get_pend() {
			this.axios.get('/edPlanFile/getFileShow', (response) => {
				this.plan_files = response.obj.records;
				this.totals = response.obj.pages * 10;
			}, {
				file_name: this.search_file_name,
				pageCount: 1,
				bdst: this.GET_BDST
			})
		},
		sp_scheme(item) {
			this.file = item
			this.title = item.fileName
			this.agreeDialogVisible = true
			this.axios.get('/edPlanFile/getLcFileById', (response) => {
				this.lcs = response.obj
				this.files = response.obj2
			}, {
				id: item.id
			})
		},
		handleClose() {
			this.agreeDialogVisible = false
			this.get_pend()
		},
		downLoad(item) {
			window.open(Decrypt(item))
		}
	},
	watch: {
		pagenum(){
			this.axios.get('/edPlanFile/getFileShow', (response) => {
				this.plan_files = response.obj.records;
				this.totals = response.obj.pages * 10;
			}, {
				file_name: this.search_file_name,
				pageCount: this.pagenum,
				bdst: this.GET_BDST
			})
		}
	}
}
</script>

<style>

</style>