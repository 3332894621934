<template>
	<div style="margin-left: 1%">
		<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
			<el-tab-pane label="我的提交" name="q">
				<My_scheme  v-if="q"/>
			</el-tab-pane>
			<el-tab-pane label="业主审批跟踪" name="w">
				<Already_approval_all  v-if="w"/>
			</el-tab-pane>
			<el-tab-pane label="方案库" name="d">
				<Scheme_storeroom  v-if="d"/>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import My_scheme from "@/views/scheme/all_scheme/my_scheme.vue";
import Already_approval_all from "@/views/scheme/all_scheme/already_approval_all.vue";
import Scheme_storeroom from "@/views/scheme/all_scheme/scheme_storeroom.vue";

export default {
	name: "",
	components: {My_scheme, Already_approval_all, Scheme_storeroom},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			activeName: 'q',
			q: true,
			w: false,
			d: false
		}
	},
	created() {
	},
	methods: {
		handleClick(tab, event){
			if (tab.props.name === 'q'){
				this.q = true
				this.w = false
				this.d = false
			}
			if (tab.props.name === 'w'){
				this.q = false
				this.w = true
				this.d = false
			}
			if (tab.props.name === 'd'){
				this.q = false
				this.w = false
				this.d = true
			}
		}
	}
}
</script>

<style>

</style>