<template>
	<div style="margin-left: 1%">
		<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
			<el-tab-pane label="待审批" name="q">
				<no_approval  v-if="q"/>
			</el-tab-pane>
			<el-tab-pane label="已审批" name="w">
				<already_approval  v-if="w"/>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import no_approval from "@/views/scheme/approval/no_approval.vue";
import already_approval from "@/views/scheme/approval/already_approval.vue";

export default {
	name: "",
	components: {no_approval, already_approval},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			activeName: 'q',
			q: true,
			w: false
		}
	},
	created() {
	},
	methods: {
		handleClick(tab, event){
			if (tab.props.name === 'q'){
				this.q = true
				this.w = false
			}
			if (tab.props.name === 'w'){
				this.q = false
				this.w = true
			}
		}
	}
}
</script>

<style>

</style>