<template>
	<div>
		<!--驳回列表-->
		<div style="min-height: 70vh">
			<el-table :data="plan_files">
				<el-table-column prop="b" label="方案名称" width="350">
					<template #default="scope">
						<el-link type="primary" :underline="false" @click="sp_scheme(scope.row)">{{ scope.row.fileName }}</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="upBdst" label="所属工区" width="180"/>
				<el-table-column prop="planNo" label="方案编号" width="180"/>
				<el-table-column prop="planType" label="方案类型" width="150"/>
				<el-table-column prop="planLevel" label="方案级别" width="180"/>
				<el-table-column prop="giveLevel" label="方案层级" width="180"/>
				<el-table-column prop="expertDemo" label="是否专家论证" width="110"/>
				<el-table-column prop="planTime" label="(计划)编制时间" width="150"/>
				<el-table-column prop="a" label="操作" width="200">
					<template #default="scope">
						<el-button type="danger" @click="update_file(scope.row)"> 修改 </el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!--审批弹窗-->
		<div>
			<el-dialog
				v-model="agreeDialogVisible"
				:title="title"
				width="60%"
				:before-close="handleClose"
			>
				<div>
					<div>
						<el-tag>附件:</el-tag>
						<div v-for="(item, index) in files" :key="index">
							<div v-if="item.fileName.split('.')[1].includes('doc')" style="margin-top: 2%">
								<el-button @click="downLoad(item.fileUrl)">
									<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/docx.png"
									     style="width: 30px;padding-top: 2%"/>
									{{ item.fileName.split('.')[0] }}
								</el-button>
							</div>
							<div v-if="item.fileName.split('.')[1].includes('pdf')" style="margin-top: 2%">
								<el-button @click="downLoad(item.fileUrl)">
									<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/PDF.png"
									     style="width: 30px;padding-top: 2%"/>
									{{ item.fileName.split('.')[0] }}
								</el-button>
							</div>
							<div v-if="item.fileName.split('.')[1].includes('xls')" style="margin-top: 2%">
								<el-button @click="downLoad(item.fileUrl)">
									<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/xlsx.png"
									     style="width: 30px;padding-top: 2%"/>
									{{ item.fileName.split('.')[0] }}
								</el-button>
							</div>
						</div>
					</div>
					<el-divider/>
					<!--	流程进度-->
					<div>
						<el-steps direction="vertical" :space="100" :active="0">
							<el-step v-for="(item, index) in lcs" :title="item.operateUser + '----------' + item.operateFlag"
							         :description="item.operateTime + '  ' +item.operateMessage"/>
							<!--<el-step title="正在处理...." />-->
						</el-steps>
					</div>
				</div>
			</el-dialog>
		</div>
	<!--	修改弹窗-->
		<div>
			<el-dialog
				v-model="updataDialogVisible"
				title="修改方案"
				width="60%"
				:before-close="handleCloseUpdate"
			>
				<div>
					<el-form :model="form" label-width="150px">
						<el-form-item label="上传类型:">
							<el-select v-model="form.type" class="m-2" placeholder="请选择方案级别" size="small" @change="change_type">
								<el-option
									v-for="item in types"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								/>
							</el-select>
						</el-form-item>
						<el-form-item label="方案名称">
							<el-input v-model="form.fileName" style="width: 17%"/>
						</el-form-item>
						<el-form-item label="方案编号:">
							<el-input v-model="form.planNo" disabled style="width: 17%"/>
						</el-form-item>
						<el-form-item label="方案类型:" v-if="form.type === '方案'">
							<el-radio-group v-model="form.planType">
								<el-radio label="安全专项方案"/>
								<el-radio label="专项技术方案"/>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="方案类别:" v-if="form.type === '方案'">
							<el-select v-model="form.palnCategory" class="m-2" placeholder="请选择方案类别" size="small">
								<el-option
									v-for="item in palnCategorys"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								/>
							</el-select>
						</el-form-item>
						<el-form-item label="方案级别:" v-if="form.type === '方案'">
							<el-select v-model="form.planLevel" class="m-2" placeholder="请选择方案级别" size="small">
								<el-option
									v-for="item in palnLevels"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								/>
							</el-select>
						</el-form-item>
						<el-form-item label="是否线下已审:">
							<el-radio-group v-model="form.notOnline">
								<el-radio label="是"/>
								<el-radio label="否"/>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="报批层级:">
							<el-select v-model="form.giveLevel" class="m-2" placeholder="请选择报批层级" size="small">
								<el-option
									v-for="item in giveLevels"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								/>
							</el-select>
						</el-form-item>
						<el-form-item label="(计划)编制时间:">
							<el-date-picker
								v-model="form.planTime"
								type="date"
								placeholder="请选择(计划)编制时间"
								format="YYYY/MM/DD"
								value-format="YYYY-MM-DD"
							/>
						</el-form-item>
						<el-form-item label="(计划)开工时间:">
							<el-date-picker
								v-model="form.workTime"
								type="date"
								placeholder="请选择(计划)开工时间"
								format="YYYY/MM/DD"
								value-format="YYYY-MM-DD"
							/>
						</el-form-item>
						<el-form-item label="是否需要专家评审:">
							<el-radio-group v-model="form.expertDemo">
								<el-radio label="是"/>
								<el-radio label="否"/>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="主要编制内容:">
							<el-input
								v-model="form.fileMain"
								maxlength="1000"
								placeholder="请输入主要编制内容"
								show-word-limit
								type="textarea"
							/>
						</el-form-item>
						<el-form-item label="上传附件:">
							<el-upload
								ref="upload"
								class="upload-demo"
								action="#"
								:http-request="upfile"
								:auto-upload="false"
								:data="dataup"
								:on-change="imgchange"
								:on-remove="handleRemove"
								:multiple="false"
							>
								<el-button type="primary" style="margin-left: 35%">请选择附件</el-button>
							</el-upload>
						</el-form-item>
					</el-form>
				</div>
				<template #footer>
                    <span class="dialog-footer">
                        <el-button type="primary" @click="up_fa">提交</el-button>
												<el-button @click="clear_form">重置</el-button>
                    </span>
				</template>
			</el-dialog>
		</div>
		<!--分页-->
		<div style="float: right;margin-top: 20px;">
			<el-pagination v-model:currentPage="pagenum"
			               :small="true"
			               background layout="prev, pager, next" :total="totals">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowTimes} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER']),
		...mapGetters(['GET_USERID'])
	},
	data() {
		return {
			plan_files: [],
			agreeDialogVisible: false,
			lcs: [],
			files: [],
			form: {
				id: '',
				type: '0',
				planNo: '',
				palnCategory: '',
				planType: '安全专项方案',
				planLevel: '',
				fileName: '',
				notOnline: '否',
				giveLevel: '',
				planTime: '',
				workTime: '',
				expertDemo: '是',
				fileMain: '',
				upUser: '',
				upBdst: '',
				upTime: '',
				userId: ''
			},
			types: [
				{
					value: '方案',
					label: '方案',
				},
				{
					value: '技术交底',
					label: '技术交底',
				},
				{
					value: '作业指导书',
					label: '作业指导书',
				}
			],
			palnCategorys: [
				{
					value: '隧道',
					label: '隧道',
				},
				{
					value: '桥梁',
					label: '桥梁',
				},
				{
					value: '路基',
					label: '路基',
				},
				{
					value: '其他',
					label: '其他',
				}
			],
			palnLevels: [
				{
					value: '危大工程',
					label: '危大工程',
				},

				{
					value: '超危工程',
					label: '超危工程',
				},
				{
					value: '其他',
					label: '其他',
				}
			],
			giveLevels: [
				{
					value: '项目公司',
					label: '项目公司',
				},
				{
					value: '监理单位',
					label: '监理单位',
				},
				{
					value: '康定项目部',
					label: '康定项目部',
				},
				{
					value: '四川公司',
					label: '四川公司',
				},
				{
					value: '川藏公司',
					label: '川藏公司',
				}
			],
			dataup: {
				file: '',
				userid: ''
			},
			fileList: [],
			updataDialogVisible: false,
			title: '',
			pagenum: 1,
			totals: 0
		}
	},
	created() {
		this.get_back()
	},
	methods: {
		change_type() {
			this.axios.get('/edPlanFile/getPlanNo', (response) => {
				this.form.planNo = response.obj
			}, {
				bdst: this.GET_BDST,
				type: this.form.type
			})
		},
		get_back() {
			this.axios.get('/edPlanFile/getBackFile', (response) => {
				this.plan_files = response.obj.records;
				this.totals = response.obj.pages * 10;
			}, {
				user_id: this.GET_USERID,
				pageCount: 1
			})
		},
		sp_scheme(item) {
			this.file = item
			this.title = item.fileName
			this.agreeDialogVisible = true
			this.axios.get('/edPlanFile/getLcFileById', (response) => {
				this.lcs = response.obj
				this.files = response.obj2
			}, {
				id: item.id,
				flag: 0
			})
		},
		handleClose() {
			this.agreeDialogVisible = false
			this.get_pend()
		},
		update_file(item){
			this.updataDialogVisible = true
			this.axios.get('/edPlanFile/getFileById', (response) => {
				this.form = response.obj
				console.log(this.form.id)
			},{
				id: item.id
			})
		},
		handleCloseUpdate(){
			this.updataDialogVisible = false
			for (let i = 0; i < this.fileList.length; i++) {
				if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
					this.fileList.splice(i, 1)
				}
			}
		},
		clear_form() {
			this.form = {
				planType: '安全专项方案',
				notOnline: '否',
				expertDemo: '是'
			}
		},
		handleRemove(file) {
			for (let i = 0; i < this.fileList.length; i++) {
				if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
					this.fileList.splice(i, 1)
				}
			}
		},
		up_fa() {
			this.form.upTime = setNowTimes(new Date())
			this.form.upUser = this.GET_USER
			this.form.upBdst = this.GET_BDST
			this.form.userId = this.GET_USERID

			this.axios.post('/edPlanFile/updataEdPlanFile', (response) => {
				if (response.obj){
					ElMessage.success('提交成功!')
					this.reload()
					this.upfile(response.obj2)
					this.axios.get('/edPlanFile/sendSpLc', (response1) => {
						for (let i = 0; i < response1.obj.length; i++){
							this.axios.post('/wxSendMessage/sendFaSp', (response2) => {

							},{
								user_id: response1.obj[i],
								flag: 0
							})
						}
					},{
						id: response.obj2
					})
				}else {
					ElMessage.error('提交失败，请联系科信部!')
				}
			}, this.form)
		},
		imgchange(file) {
			this.fileList.push(file);
		},
		upfile(uuid) {
			//https://www.cr12cz.cn/czscsms/up/upload
			this.fileList.forEach((item, index) => {
				this.axios.post("https://www.cr12cz.cn/czscsms/up/upload", (response) => {
					if (response.obj) {
						this.axios.post('/edPlanFileUrl/saveFileUrl', (response1) => {
							if (!response1.obj){
								ElMessage.error('提交失败，请联系科信部!')
							}
						},{
							file_name: response.message,
							file_url: response.obj,
							main_id: uuid,
							last_flag: 0
						})
						this.fileList = []
						this.dataup = {}
						this.$refs.uploadDemo.clearFiles()
					}else {
						ElMessage.error('提交失败，请联系科信部!')
					}
				}, {
					file: item.raw,
					userid: this.GET_USERID
				})
			})
		}
	},
	watch: {
		pagenum(){
			this.axios.get('/edPlanFile/getBackFile', (response) => {
				this.plan_files = response.obj.records;
				this.totals = response.obj.pages * 10;
			}, {
				user_id: this.GET_USERID,
				pageCount: this.pagenum
			})
		}
	}
}
</script>

<style>

</style>