<template>
	<div>
		<!--	基础信息-->
		<div style="width: 85%;">
			<el-form :model="form" label-width="150px">
				<el-form-item label="上传类型:">
					<el-select v-model="form.type" class="m-2" placeholder="请选择方案级别" size="small" @change="change_type">
						<el-option
							v-for="item in types"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="方案名称">
					<el-input v-model="form.fileName" style="width: 17%"/>
				</el-form-item>
				<el-form-item label="方案编号:">
					<el-input v-model="form.planNo" disabled style="width: 17%"/>
				</el-form-item>
				<el-form-item label="方案类型:">
					<el-radio-group v-model="form.planType">
						<el-radio label="安全专项方案"/>
						<el-radio label="专项技术方案"/>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="方案类别:">
					<el-select v-model="form.palnCategory" class="m-2" placeholder="请选择方案类别" size="small">
						<el-option
							v-for="item in palnCategorys"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="方案级别:">
					<el-select v-model="form.planLevel" class="m-2" placeholder="请选择方案级别" size="small">
						<el-option
							v-for="item in palnLevels"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="是否线下已审:">
					<el-radio-group v-model="form.notOnline">
						<el-radio label="是"/>
						<el-radio label="否"/>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="报批层级:">
					<el-select v-model="form.giveLevel" class="m-2" placeholder="请选择报批层级" size="small">
						<el-option
							v-for="item in giveLevels"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="(计划)编制时间:">
					<el-date-picker
						v-model="form.planTime"
						type="date"
						placeholder="请选择(计划)编制时间"
						format="YYYY-MM-DD"
						value-format="YYYY-MM-DD"
					/>
				</el-form-item>
				<el-form-item label="(计划)开工时间:">
					<el-date-picker
						v-model="form.workTime"
						type="date"
						placeholder="请选择(计划)开工时间"
						format="YYYY-MM-DD"
						value-format="YYYY-MM-DD"
					/>
				</el-form-item>
				<el-form-item label="是否需要专家评审:">
					<el-radio-group v-model="form.expertDemo">
						<el-radio label="是"/>
						<el-radio label="否"/>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="主要编制内容:">
					<el-input
						v-model="form.fileMain"
						maxlength="1000"
						placeholder="请输入主要编制内容"
						show-word-limit
						type="textarea"
					/>
				</el-form-item>
				<el-form-item label="上传附件:">
					<el-upload
						ref="upload"
						class="upload-demo"
						action="#"
						:http-request="upfile"
						:auto-upload="false"
						:data="dataup"
						:on-change="imgchange"
						:on-remove="handleRemove"
						:multiple="false"
					>
						<el-button type="primary" style="margin-left: 35%">请选择附件</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="up_fa">提交</el-button>
					<el-button @click="clear_form">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowTimes} from "@/utils/time";
import {Decrypt} from "@/utils/secret";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER']),
		...mapGetters(['GET_USERID'])
	},
	data() {
		return {
			form: {
				type: '专项施工方案',
				planNo: '',
				palnCategory: '',
				planType: '安全专项方案',
				planLevel: '',
				fileName: '',
				notOnline: '否',
				giveLevel: '',
				planTime: '',
				workTime: '',
				expertDemo: '是',
				fileMain: '',
				upUser: '',
				upBdst: '',
				upTime: '',
				userId: ''
			},
			types: [
				{
					value: '专项施工方案',
					label: '专项施工方案',
				},
				{
					value: '实施性施工组织设计',
					label: '实施性施工组织设计',
				},
				{
					value: '作业指导书',
					label: '作业指导书',
				}
			],
			palnCategorys: [
				{
					value: '隧道',
					label: '隧道',
				},
				{
					value: '桥梁',
					label: '桥梁',
				},
				{
					value: '路基',
					label: '路基',
				},
				{
					value: '其他',
					label: '其他',
				}
			],
			palnLevels: [
				{
					value: '危大工程',
					label: '危大工程',
				},

				{
					value: '超危工程',
					label: '超危工程',
				},
				{
					value: '其他',
					label: '其他',
				}
			],
			giveLevels: [
				{
					value: '项目公司',
					label: '项目公司',
				},
				{
					value: '监理单位',
					label: '监理单位',
				},
				{
					value: '康定项目部',
					label: '康定项目部',
				},
				{
					value: '四川公司',
					label: '四川公司',
				},
				{
					value: '川藏公司',
					label: '川藏公司',
				}
			],
			dataup: {
				file: '',
				userid: ''
			},
			fileList: [],
		}
	},
	created() {
		this.change_type()
	},
	methods: {
		change_type() {
			this.axios.get('/edPlanFile/getPlanNo', (response) => {
				this.form.planNo = response.obj
			}, {
				bdst: this.GET_BDST,
				type: this.form.type
			})
		},
		clear_form() {
			this.form = {
				planType: '安全专项方案',
				notOnline: '否',
				expertDemo: '是'
			}
		},
		up_fa() {
			this.form.upTime = setNowTimes(new Date())
			this.form.upUser = this.GET_USER
			this.form.upBdst = this.GET_BDST
			this.form.userId = this.GET_USERID
			this.axios.post('/edPlanFile/saveEdPlanFile', (response) => {
				if (response.obj){
					ElMessage.success('提交成功!')
					this.reload()
					this.upfile(response.obj2)
					this.axios.get('/edPlanFile/sendSpLc', (response1) => {
						for (let i = 0; i < response1.obj.length; i++){
							this.axios.post('/wxSendMessage/sendFaSp', (response2) => {

							},{
								user_id: response1.obj[i]
							})
						}
					},{
						id: response.obj2
					})
				}else {
					ElMessage.error('提交失败，请联系科信部!')
				}
			}, this.form)
		},
		imgchange(file) {
			this.fileList.push(file);
		},
		handleRemove(file) {
			for (let i = 0; i < this.fileList.length; i++) {
				if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
					this.fileList.splice(i, 1)
				}
			}
		},
		upfile(uuid) {
			//https://www.cr12cz.cn/czscsms/up/upload
			this.fileList.forEach((item, index) => {
				this.axios.post("https://www.cr12cz.cn/czscsms/up/upload", (response) => {
					if (response.obj) {
						this.axios.post('/edPlanFileUrl/saveFileUrl', (response1) => {
							if (!response1.obj){
								ElMessage.error('提交失败，请联系科信部!')
							}
						},{
							file_name: response.message,
							file_url: response.obj,
							main_id: uuid,
							last_flag: 0
						})
						this.fileList = []
						this.dataup = {}
						this.$refs.uploadDemo.clearFiles()
					}else {
						ElMessage.error('提交失败，请联系科信部!')
					}
				}, {
					file: item.raw,
					userid: this.GET_USERID
				})
			})
		}
	}
}
</script>

<style>

</style>