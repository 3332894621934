<template>
	<div>
		<div style="min-height: 70vh">
			<el-table :data="plan_files">
				<el-table-column prop="b" label="方案名称" width="350">
					<template #default="scope">
						<el-link type="primary" :underline="false" @click="sp_scheme(scope.row)">{{ scope.row.fileName }}</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="upBdst" label="所属工区" width="180"/>
				<el-table-column prop="planNo" label="方案编号" width="180"/>
				<el-table-column prop="planType" label="方案类型" width="180"/>
				<el-table-column prop="planLevel" label="方案级别" width="180"/>
				<el-table-column prop="giveLevel" label="审批层级" width="180"/>
				<el-table-column prop="expertDemo" label="是否专家论证" width="180"/>
				<el-table-column prop="planTime" label="(计划)编制时间" width="150"/>
				<!--<el-table-column prop="a" label="操作" width="200">-->
				<!--	<template #default="scope">-->
				<!--		<el-button type="primary" @click="update_room(scope.row)"> 修改 </el-button>-->
				<!--		<el-popconfirm-->
				<!--			width="220"-->
				<!--			confirm-button-text="确认"-->
				<!--			cancel-button-text="取消"-->
				<!--			:icon="InfoFilled"-->
				<!--			icon-color="#626AEF"-->
				<!--			title="是否确认删除?"-->
				<!--			@confirm="del_room(scope.row)"-->
				<!--		>-->
				<!--			<template #reference>-->
				<!--				<el-button type="danger"> 删除 </el-button>-->
				<!--			</template>-->
				<!--		</el-popconfirm>-->

				<!--	</template>-->
				<!--</el-table-column>-->
			</el-table>
		</div>
		<!--	审批弹窗-->
		<div>
			<el-dialog
				v-model="agreeDialogVisible"
				:title="title"
				width="60%"
				:before-close="handleClose"
			>
				<div>
					<div style="display: flex;width: 100%">
						<div style="width: 50%;">
							<el-tag>附件:</el-tag>
							<div v-for="(item, index) in files" :key="index">
								<div v-if="item.fileName.split('.')[1].includes('doc')" style="margin-top: 2%">
									<el-button @click="downLoad(item.fileUrl)">
										<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/docx.png"
										     style="width: 30px;padding-top: 2%"/>
										{{ item.fileName.split('.')[0] }}
									</el-button>
								</div>
								<div v-if="item.fileName.split('.')[1].includes('pdf')" style="margin-top: 2%">
									<el-button @click="downLoad(item.fileUrl)">
										<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/PDF.png"
										     style="width: 30px;padding-top: 2%"/>
										{{ item.fileName.split('.')[0] }}
									</el-button>
								</div>
								<div v-if="item.fileName.split('.')[1].includes('xls')" style="margin-top: 2%">
									<el-button @click="downLoad(item.fileUrl)">
										<img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/xlsx.png"
										     style="width: 30px;padding-top: 2%"/>
										{{ item.fileName.split('.')[0] }}
									</el-button>
								</div>
							</div>
						</div>

					<!--	已抄送人员-->
						<div style="width: 50%;margin-left: 20%">
							<div>
								<el-tag type="warning">已抄送人员:</el-tag>
							</div>
							<div style="margin-top: 2%">
								<el-tag v-for="(item, index) in alery_copy_user" :key="index" style="margin-right: 2%;margin-top: 2%">
									{{ item.userName }}
								</el-tag>
							</div>
						</div>

					</div>
					<el-divider/>
					<!--	流程进度-->
					<div>
						<el-steps direction="vertical" :space="100" :active="0">
							<el-step v-for="(item, index) in lcs" :title="item.operateUser + '----------' + item.operateFlag"
							         :description="item.operateTime + '  ' +item.operateMessage"/>
							<!--<el-step title="正在处理...." />-->
						</el-steps>
					</div>
					<el-divider/>
					<!--	意见-->
					<div>
						<el-input
							v-model="operate_message"
							:rows="2"
							type="textarea"
							placeholder="请输入审批意见"
						/>
					</div>
				</div>
				<template #footer>
                    <span class="dialog-footer">
                        <el-button @click="agree_back('驳回')" type="danger">驳回</el-button>
	                      <el-button type="warning" @click="copy_user()"> 抄送 </el-button>
                        <el-button type="primary" @click="agree_back('同意')"> 同意 </el-button>

                    </span>
				</template>
			</el-dialog>
		</div>
		<!--抄送弹窗-->
		<div>
			<el-dialog
				v-model="dialogCopyVisible"
				title="抄送方案"
				width="60%"
				:before-close="handleCloseCopy"
			>
				<div>
					<div>
						<el-tag>所属单位</el-tag>
						<el-select v-model="save_bdst" class="m-2" placeholder="请选择工区" size="small" style="width: 30%"
						           @change="getUserByBdst">
							<el-option
								v-for="item in bdsts"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							/>
						</el-select>
					</div>

					<div style="margin-top: 3%">
						<el-tag>抄送人员</el-tag>
						<el-select v-model="bdst_user" class="m-2" placeholder="请选择抄送人员" size="small" style="width: 30%" @change="push_user">
							<el-option
								v-for="item in bdst_user_list"
								:key="item.id"
								:label="item.name"
								:value="item.id + '$' + item.name"
							/>
						</el-select>
					</div>
				<!--	展示抄送人员-->
					<div style="margin-top: 2%">
						<el-tag v-for="(item, index) in copy_user_id"
						        :key="index"
						        style="margin-right: 1%"
						        type="danger"
						        @dblclick="del_user_id(index)">
							{{ item.split('$')[1] }}
						</el-tag>
					</div>

				</div>
				<template #footer>
                    <span class="dialog-footer">
                        <el-button @click="handleCloseCopy">取消</el-button>
                        <el-button type="primary" @click="save_copy_user"> 确认 </el-button>
                    </span>
				</template>
			</el-dialog>
		</div>
		<!--分页-->
		<div style="float: right;margin-top: 20px;">
			<el-pagination v-model:currentPage="pagenum"
			               :small="true"
			               background layout="prev, pager, next" :total="totals">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {Decrypt} from "@/utils/secret";
import {setNowTimes} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER']),
		...mapGetters(['GET_USERID'])
	},
	data() {
		return {
			plan_files: [],
			agreeDialogVisible: false,
			files: [],
			lcs: [],
			title: '',
			operate_message: '',
			file: '',
			pagenum: 1,
			totals: 0,
			dialogCopyVisible: false,
			bdst_user_list: [],
			bdst_user: '',
			bdsts: [
				{
					value: '1',
					label: '项目公司',
				},
				{
					value: '21',
					label: '2标一工区',
				},
				{
					value: '22',
					label: '2标二工区',
				},
				{
					value: '51',
					label: '5标一工区',
				},
				{
					value: '52',
					label: '5标二工区',
				},
				{
					value: '53',
					label: '5标三工区',
				},
				{
					value: '54',
					label: '5标四工区',
				},
				{
					value: '55',
					label: '5标五工区',
				},
				{
					value: '56',
					label: '5标六工区',
				},
				{
					value: '57',
					label: '5标七工区',
				}
			],
			save_bdst: '',
			copy_user_id: [],
			alery_copy_user: []
		}
	},
	created() {
		this.get_pend()
	},
	methods: {
		get_pend() {
			this.axios.get('/edPlanFile/getPendPlan', (response) => {
				this.plan_files = response.obj.records;
				this.totals = response.obj.pages * 10;
			}, {
				user_id: this.GET_USERID,
				pageCount: 1
			})
		},
		sp_scheme(item) {
			this.file = item
			this.title = item.fileName
			this.agreeDialogVisible = true
			this.axios.get('/edPlanFile/getLcFileById', (response) => {
				this.lcs = response.obj
				this.files = response.obj2
			}, {
				id: item.id,
				flag : 0
			})
			this.axios.get('/edCopy/getCopyUser', (response) => {
				this.alery_copy_user = response.obj
			},{
				file_id: item.id
			})
		},
		// 获取抄送人员
		getCopyUser(){
			this.axios.get('/edCopy/getCopyUser', (response) => {
				this.alery_copy_user = response.obj
			},{
				file_id: this.file.id
			})
		},
		handleClose() {
			this.agreeDialogVisible = false
			this.get_pend()
		},
		downLoad(item) {
			window.open(Decrypt(item))
		},
		agree_back(item){
			if (this.operate_message === ''){
				this.operate_message = item
			}
			this.axios.post('/edPlanFile/agreeBackById', (response) => {
				if (response.obj){
					ElMessage.success('审批成功!')
					this.operate_message = ''
					this.sp_scheme(this.file)
					if (item === '同意'){
						this.axios.get('/edPlanFile/sendSpLc', (response1) => {
							for (let i = 0; i < response1.obj.length; i++){
								this.axios.post('/wxSendMessage/sendFaSp', (response2) => {

								},{
									user_id: response1.obj[i],
									flag: 0
								})
							}
						},{
							id: this.file.id
						})
					}else {
						this.axios.get('/edPlanFile/sendSpLc', (response1) => {
							for (let i = 0; i < response1.obj.length; i++){
								this.axios.post('/wxSendMessage/sendFaSp', (response2) => {

								},{
									user_id: response1.obj[i],
									flag: 1
								})
							}
						},{
							id: this.file.id
						})
					}
				}
			},{
				id: this.file.id,
				operate_message: this.operate_message,
				flag: item,
				user: this.GET_USER,
				time: setNowTimes(new Date()),
				user_id: this.GET_USERID
			})
		},
		copy_user(){
			this.dialogCopyVisible = true
		},
		// 根据工区获取人员信息
		getUserByBdst() {
			this.axios.get('/userinfo/getUserByBdst', (response) => {
				this.bdst_user_list = response.obj
			}, {
				bdst: this.save_bdst
			})
		},
		save_copy_user(){
			console.log(this.copy_user_id.length)
			this.axios.post('/edCopy/saveCopyUser', (response) => {
				if (response.obj){
					ElMessage.success('抄送成功!')

					for (let i = 0; i < this.copy_user_id.length; i++){
						this.axios.post('/wxSendMessage/sendFaCopy', (response2) => {

						},{
							user_id: this.copy_user_id[i].split('$')[0]
						})
					}
					this.handleCloseCopy()
				}else {
					ElMessage.error('抄送失败!')
				}
			},{
				file_id: this.file.id,
				user_ids: this.copy_user_id,
				time: setNowTimes(new Date())
			})

		},
		handleCloseCopy(){
			this.dialogCopyVisible = false
			this.save_bdst = ''
			this.bdst_user = ''
			this.copy_user_id = []
			this.getCopyUser()
		},
		push_user(){
			this.axios.get('/edCopy/getUserByFiledUser', (response) => {
				if (response.obj){
					this.copy_user_id.push(this.bdst_user)
				}
			},{
				file_id: this.file.id,
				user_id: this.bdst_user.split('$')[0]
			})
		},
		del_user_id(item){
			this.copy_user_id.splice(item, 1)
		}
	},
	watch: {
		pagenum(){
			this.axios.get('/edPlanFile/getPendPlan', (response) => {
				this.plan_files = response.obj.records;
				this.totals = response.obj.pages * 10;
			}, {
				user_id: this.GET_USERID,
				pageCount: this.pagenum
			})
		}
	}
}
</script>

<style>

</style>